.fc-v-event {
    border: 0;
    border-radius: 5px;
    background-color: white !important;
}

a.fc-event {
    height: auto;
}

.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background-color: white;
    padding: 20px;
}
